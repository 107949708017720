import {createReducer} from '@reduxjs/toolkit';
import {
    CollectionTypeEnum,
    MortgageTypeEnum,
    PolicyTypeEnum,
    PropertyTypeEnum,
    TransactionTypeEnum
} from 'Model/PolicyOrder';
import {MortgageHelper, PropertyHelper, PurchaserHelper} from 'Policy/PolicyOrderHelper';
import {StringUtil} from 'Util/Helpers';
import Logger from 'Util/Logger';
import {QuestionaryHelper} from 'Util/QuestionaryHelper';
import {SectionHelper} from 'Util/SectionHelper';
import * as actions from './PolicyActions';

const logger = new Logger("PolicyReducer");

const initialState = {
    questionaryId: '',
    questionaryVersion: 1,
    province: '',
    source:'',
    properties: [],
    purchasers: [],
    mortgages: [],
    remainingMortgages: [],
    nhpFreeholdLegalDescriptions: [],
    nhpCondoLegalDescriptions: [],
    policyMap: [],
    premium: {},

    orderChanges: {},
    orderChangesCount: 0,
    formState: {},
    isSaving: false,
    actionMsg: { type: 'success', message: '' },
    orderVolatileData: {}, /* things like status that tends to change */
    shadowChanges: {},
    reloadQuestionaryEvent: {}, /* used to signal that questionary needs reloading */
    loadProjectPropertyEvent: {},
    questionary: {},
    generatingPDF: {},
    viewGeneratedPDF: {},

    premiumChanges: 0,
    isCalculatingPremium: false
}

function resolveAllowSave(state) {
    const permittedActions = state.orderVolatileData.PermittedActions;

    const allowSave = StringUtil.contains(permittedActions, "Save");

    return allowSave;
}

const PolicyReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actions.set_new_order, (state, action) => {
            const orderData = action.payload;

            // normally we want more fine grained ... but lazy
            state.questionaryId = orderData.po.QuestionaryId;
            state.questionaryVersion = orderData.po.QuestionaryVersion;

            state.province = orderData.province;
            state.closingDate = orderData.po.ClosingDate;
            state.source = orderData.source;
            state.properties = orderData.properties;
            state.purchasers = orderData.purchasers;
            state.mortgages = orderData.mortgages;
            state.remainingMortgages = orderData.remainingMortgages;
            state.nhpFreeholdLegalDescriptions = orderData.nhpFreeholdLegalDescriptions;
            state.nhpCondoLegalDescriptions = orderData.nhpCondoLegalDescriptions;
            state.policyMap = orderData.policyMap;
            state.premium = orderData.premium;
            state.exceptions = orderData.exceptions;
            state.orderVolatileData = orderData.po;

            state.orderChanges = {};
            state.orderChangesCount = 0;
            state.formState = {};
            state.isSaving = false;
            state.actionMsg = { type: 'success', message: '' };
            state.reloadQuestionaryEvent = {};
            state.questionary = {};
            state.generatingPDF = {};
            state.viewGeneratedPDF = {};

            setMiscellaneousProps(state);
        })
        // PROPERTIES
        .addCase(actions.add_property, (state, action) => {
            state.properties.push(action.payload);
        })
        .addCase(actions.remove_property, (state, action) => {
            const item = action.payload;

            state.properties = state.properties.filter(x => !StringUtil.isEqual(x.Id, item.Id));
        })
        // PURCHASERS
        .addCase(actions.add_purchaser, (state, action) => {
            state.purchasers.push(action.payload);
        })
        .addCase(actions.remove_purchaser, (state, action) => {
            const item = action.payload;

            state.purchasers = state.purchasers.filter(x => !StringUtil.isEqual(x.Id, item.Id));

            setMiscellaneousProps(state);
        })
        // MORTGAGES
        .addCase(actions.add_mortgage, (state, action) => {
            state.mortgages.push(action.payload);

            setMiscellaneousProps(state);
        })
        .addCase(actions.remove_mortgage, (state, action) => {
            const item = action.payload;

            state.mortgages = state.mortgages.filter(x => !StringUtil.isEqual(x.Id, item.Id));

            setMiscellaneousProps(state);
        })
        // REMAINING MORTGAGES
        .addCase(actions.add_remaining_mortgage, (state, action) => {
            state.remainingMortgages.push(action.payload);
        })
        .addCase(actions.remove_remaining_mortgage, (state, action) => {
            const item = action.payload;

            state.remainingMortgages = state.remainingMortgages.filter(x => !StringUtil.isEqual(x.Id, item.Id));
        })
        .addCase(actions.add_nph_freehold_legal_description, (state, action) => {
            state.nhpFreeholdLegalDescriptions.push(action.payload);
            // setMiscellaneousProps(state);
        })
        .addCase(actions.remove_nph_freehold_legal_description, (state, action) => {
            const item = action.payload;
            state.nhpFreeholdLegalDescriptions = state.nhpFreeholdLegalDescriptions.filter(x => !StringUtil.isEqual(x.Id, item.Id));
        })
        .addCase(actions.add_nph_condo_legal_description, (state, action) => {
            state.nhpCondoLegalDescriptions.push(action.payload);
            // setMiscellaneousProps(state);
        })
        .addCase(actions.remove_nph_condo_legal_description, (state, action) => {
            const item = action.payload;
            state.nhpCondoLegalDescriptions = state.nhpCondoLegalDescriptions.filter(x => !StringUtil.isEqual(x.Id, item.Id));
            // setMiscellaneousProps(state);
        })
        .addCase(actions.changeOrder, (state, action) => {
            const { fieldName, fieldValue } = action.payload;

            const allowSave = resolveAllowSave(state);

            if (!allowSave) {
                logger.debug("save is not in permitted actions, order is read only");

                return;
            }

            const currValue = state.orderChanges[fieldName];

            if (StringUtil.endsWith(fieldName, '.IsDeleted')) {
                const fieldsToRemove = SectionHelper.getSectionsAnswersToRemove(fieldName, state.orderChanges);

                if (fieldsToRemove.length > 0) {
                    for (const fieldToRemove of fieldsToRemove) {
                        delete state.orderChanges[fieldToRemove];
                    }
                }
            }

            if (currValue === fieldValue) {
                return; // do not change anything...
            }

            state.orderChanges[fieldName] = fieldValue;
            state.orderChangesCount = state.orderChangesCount + 1; // bump up the changes count ...

            reloadQuestionary(fieldName, fieldValue, state);
            setMiscellaneousProps(state, fieldName, fieldValue);
        })
        .addCase(actions.shadowUpdateOrder, (state, action) => {
            const { propertyInfo } = action.payload;

            state.shadowChanges = {
                ...state.shadowChanges,
                ...propertyInfo
            };
            //TODO
            //state.shadowChanges

        })
        .addCase(actions.setValidationResult, (state, action) => {
            const { validationMap } = action.payload;

            if (!validationMap) {
                return;
            }

            const formStateUpdate = {};

            // fieldName should alreay be lowercase ...
            for (const fieldName of Object.keys(validationMap)) {
                let fieldState = state.formState[fieldName];
                const validationError = validationMap[fieldName];
                const isError = StringUtil.isEqual(validationError.Severity, "error");

                let newState;

                if (fieldState) {
                    newState = { ...fieldState };
                }
                else {
                    newState = { name: fieldName };
                }

                newState.error = isError;
                newState.helperText = validationError.ErrorMessage;

                formStateUpdate[fieldName] = newState;
            }

            state.formState = { ...state.formState, ...formStateUpdate };
        })
        .addCase(actions.clearValidationError, (state, action) => {
            const { fieldName } = action.payload;

            if (!fieldName) {
                return;
            }

            const allowSave = resolveAllowSave(state);

            if (!allowSave) {
                logger.debug("save is not in permitted actions, order is read only");

                return;
            }

            const fieldNameLower = fieldName.toLowerCase();

            const fieldState = state.formState[fieldNameLower];

            if (!fieldState) { return; }

            const newState = { ...fieldState };

            newState.error = false;
            newState.helperText = '';

            state.formState[fieldNameLower] = newState;
        })
        .addCase(actions.setIsSaving, (state, action) => {
            state.isSaving = action.payload;
        })
        .addCase(actions.setJustSaved, (state, action) => {
            state.orderChangesCount = 0; // reset as we just saved
        })
        .addCase(actions.setActionMessage, (state, action) => {
            state.actionMsg = action.payload;
        })
        .addCase(actions.clearActionMessage, (state, action) => {
            state.actionMsg.message = '';
        })
        .addCase(actions.setOrderVolatileData, (state, action) => {
            const newData = action.payload;
            state.orderVolatileData = newData; // we override in whole
        })
        .addCase(actions.setPolicyMap, (state, action) => {
            const policyMap = action.payload;
            state.policyMap = policyMap; // we override in whole
        })
        .addCase(actions.setQuestionary, (state, action) => {
            const questionary = action.payload;
            state.questionary = questionary; // we override in whole
        })
        .addCase(actions.generatingPDF, (state, action) => {
            state.generatingPDF = action.payload;
        })
        .addCase(actions.viewGeneratedPDF, (state, action) => {
            state.viewGeneratedPDF = action.payload;
        })
        .addCase(actions.setIsCalculatingPremium, (state, action) => {
            state.isCalculatingPremium = action.payload;
        })
        .addCase(actions.setPremium, (state, action) => {
            const newPremium = action.payload;

            state.premium = newPremium; // we override in whole
            state.orderChangesCount += 1; //Trigger autosave
        })

        .addDefaultCase((state, action) => { })
});

function reloadQuestionary(fieldName, fieldValue, state) {
    if (fieldName.endsWith('.NHPCode')) {
        state.loadProjectPropertyEvent = { NHPCode: fieldValue, TriggeringField: fieldName };
        return;
    }

    if (fieldName.endsWith('.TransactionType')
        || fieldName.endsWith('.PropertyType')
        || fieldName.endsWith('.IsEnrolledInNHP')
        || fieldName.endsWith('.PropertyAddressProvince')
        || fieldName.endsWith('.AddExistingOwnerPolicy')
        || fieldName.endsWith('.ExistingOwnerHasTIPolicy')
        || StringUtil.isEqual(fieldName, 'Province')) {

        const indexer = QuestionaryHelper.getIndexer(fieldName);
        const fldName = fieldName.replace(indexer, '');

        const transTypeFieldName = fieldName.replace(fldName, 'TransactionType');
        const propTypeFieldName = fieldName.replace(fldName, 'PropertyType');
        const isEnrolledNHPFieldName = fieldName.replace(fldName, 'IsEnrolledInNHP');
        const nhpCodeFieldName = fieldName.replace(fldName, 'NHPCode');
        const propertyAddressProvinceFieldName = fieldName.replace(fldName, 'PropertyAddressProvince');
        const addExistingOwnerPolicyFieldName = fieldName.replace(fldName, 'AddExistingOwnerPolicy');
        const existingOwnerHasTIPolicyFieldName = fieldName.replace(fldName, 'ExistingOwnerHasTIPolicy');

        const TransactionType = state.orderChanges[transTypeFieldName];
        const PropertyType = state.orderChanges[propTypeFieldName];
        const IsEnrolledInNHP = state.orderChanges[isEnrolledNHPFieldName];
        const NHPCode = state.orderChanges[nhpCodeFieldName];
        const AddExistingOwnerPolicy = state.orderChanges[addExistingOwnerPolicyFieldName];
        const ExistingOwnerHasTIPolicy = state.orderChanges[existingOwnerHasTIPolicyFieldName];
        let PropertyAddressProvince = state.orderChanges[propertyAddressProvinceFieldName];

        if (StringUtil.isEqual(fieldName, 'Province')) {
            PropertyAddressProvince = fieldValue;
        }

        let _isEnrolledInNHP = 'no';
        if (StringUtil.isEqual(TransactionType, TransactionTypeEnum.New))
            if (StringUtil.isYes(IsEnrolledInNHP))
                if (!StringUtil.isNullEmptyOrWhiteSpace(NHPCode))
                    _isEnrolledInNHP = state.orderChanges[isEnrolledNHPFieldName];

        if (!StringUtil.isNullOrEmpty(TransactionType)
            || !StringUtil.isNullOrEmpty(PropertyType)
            || !StringUtil.isNullOrEmpty(_isEnrolledInNHP)
            || !StringUtil.isNullOrEmpty(NHPCode)
            || !StringUtil.isNullOrEmpty(PropertyAddressProvince)
            || !StringUtil.isNullOrEmpty(AddExistingOwnerPolicy)
            || !StringUtil.isNullOrEmpty(ExistingOwnerHasTIPolicy)) {

            state.reloadQuestionaryEvent = {
                TransactionType,
                PropertyType,
                IsEnrolledInNHP: _isEnrolledInNHP,
                NHPCode,
                PropertyAddressProvince,
                AddExistingOwnerPolicy,
                ExistingOwnerHasTIPolicy,
                QuestionaryId: state.questionaryId,
                QuestionaryVersion: state.questionaryVersion,
                TriggeringField: fieldName
            }; // signal that questionary should be reloaded
        }
    }
}

function setMiscellaneousProps(state, fieldName, fieldValue) {
    const propertyHelper = new PropertyHelper(state);
    const mortgageHelper = new MortgageHelper(state);
    const purchasersHelper = new PurchaserHelper(state);

    let hasPurchaseTransaction = false;
    let hasExistingOwnerTransaction = false;
    let isMOEOTransaction = false;

    for (const p of state.properties.map(property => propertyHelper.getItemTrueState(property))) {
        const propertyIndexer = QuestionaryHelper.createIndexer(CollectionTypeEnum.Properties, p);

        //#region Property        
        const noExecutionReasonRequired = StringUtil.isNo(p.ExecAgainstVendorPurchaserResolved) || StringUtil.isNo(p.ExecAgainstBorrowerResolved) || StringUtil.isNo(p.ExecAgainstVendorResolved);
        const certificateInformationRequired = StringUtil.isYes(p.IsParcelOfTiedLand) || StringUtil.isEither(p.PropertyType, PropertyTypeEnum.Condominium, PropertyTypeEnum.VacantLandCondo);

        state.orderChanges[`${propertyIndexer}TransactionType`] = p.TransactionType;
        state.orderChanges[`${propertyIndexer}PropertyType`] = p.PropertyType;
        state.orderChanges[`${propertyIndexer}IsEnrolledInNHP`] = p.IsEnrolledInNHP;
        state.orderChanges[`${propertyIndexer}NHPCode`] = p.NHPCode;
        state.orderChanges[`${propertyIndexer}ShowFraudSection`] = !StringUtil.isNullOrEmpty(p.TransactionType) || !StringUtil.isNullOrEmpty(p.PropertyType) || StringUtil.isYes(p.IsEnrolledInNHP);
        state.orderChanges[`${propertyIndexer}NoExecutionReasonRequired`] = noExecutionReasonRequired;
        state.orderChanges[`${propertyIndexer}CertificateInformationRequired`] = certificateInformationRequired;

        state.orderChanges[`${propertyIndexer}PropertyAddressProvince`] = p.PropertyAddressProvince;

        if (StringUtil.isEqual(fieldName, 'Province')) {
            state.orderChanges[`${propertyIndexer}PropertyAddressProvince`] = fieldValue;
            state.shadowChanges[`${propertyIndexer}PropertyAddressProvince`] = fieldValue;
            state.province = fieldValue;
        }

        if (StringUtil.isEqual(fieldName, 'ClosingDate')) {
            state.closingDate = fieldValue;
        }

        if (StringUtil.isEither(p.TransactionType, TransactionTypeEnum.New, TransactionTypeEnum.Resale)) {
            hasPurchaseTransaction = true;
        }

        if (StringUtil.isEither(p.TransactionType, TransactionTypeEnum.ExistingOwner)) {
            hasExistingOwnerTransaction = true;
        }

        if (StringUtil.isEither(p.TransactionType, TransactionTypeEnum.MortgageOnly)) {
            if(StringUtil.isYes(p.AddExistingOwnerPolicy))
            isMOEOTransaction = true;
        }
        //#endregion Property

        //#region Mortgages
        const hasMortgage =  StringUtil.isYes(p.IsMortgagePresent) && StringUtil.isEither(p.CoverageType, PolicyTypeEnum.LenderPolicyOnly, PolicyTypeEnum.OwnerAndLenderPolicy);
        const hasTpInsuredMortgage = StringUtil.isYes(p.IsMortgagePresent) && mortgageHelper.any(x => StringUtil.isEqual(p.Id, x.MortgagePropertyId) && StringUtil.isYes(x.MortgageTPInsured));
        const totalMortgageAmount = mortgageHelper.sum(x => StringUtil.isEqual(p.Id, x.MortgagePropertyId), 'MortgagePrincipalAmount');
        const totalPvtMortgageAmount = mortgageHelper.sum(x => StringUtil.isEqual(p.Id, x.MortgagePropertyId) && StringUtil.isEqual(x.MortgageType, MortgageTypeEnum.Private), 'MortgagePrincipalAmount');
        const hasPvtLenderMortgage = mortgageHelper.any(x => StringUtil.isEqual(p.Id, x.MortgagePropertyId) && StringUtil.isEqual(x.MortgageType, MortgageTypeEnum.Private));
        const hasMortgageClosingPriorityOfLessThan1 = mortgageHelper.any(x => StringUtil.isEqual(p.Id, x.MortgagePropertyId) && StringUtil.toDecimal(x.PriorityUponClosing) > 1);
        const hasMortgageClosingPriorityOfLessThan3 = mortgageHelper.any(x => StringUtil.isEqual(p.Id, x.MortgagePropertyId) && StringUtil.toDecimal(x.PriorityUponClosing) >= 3);
        const hideFraudQuestion = hasPvtLenderMortgage && hasPurchaseTransaction && p.CoverageType === PolicyTypeEnum.OwnerPolicyOnly;

        let shouldShowMortgageSection = false;
        if (StringUtil.isEither(p.TransactionType, TransactionTypeEnum.Resale, TransactionTypeEnum.New))
            if (StringUtil.isYes(p.IsMortgagePresent) && StringUtil.isEither(p.CoverageType, PolicyTypeEnum.LenderPolicyOnly, PolicyTypeEnum.OwnerAndLenderPolicy))
                shouldShowMortgageSection = true;

        if (StringUtil.isEqual(p.TransactionType, TransactionTypeEnum.MortgageOnly))
            shouldShowMortgageSection = true;

        for (const m of state.mortgages.map(x => mortgageHelper.getItemTrueState(x))) {
            const mortgageIndexer = QuestionaryHelper.createIndexer(CollectionTypeEnum.Mortgages, m);
            let shouldShowMortgageDetails = false;
            let isMortgageAdvanceDateDifferent = false;
            if (StringUtil.isEqual(m.MortgagePropertyId, p.Id)) {
                if (StringUtil.isEither(p.TransactionType, TransactionTypeEnum.Resale, TransactionTypeEnum.New)) {
                    if (StringUtil.isYes(p.IsMortgagePresent) && StringUtil.isEither(p.CoverageType, PolicyTypeEnum.LenderPolicyOnly, PolicyTypeEnum.OwnerAndLenderPolicy)) {
                        if (StringUtil.isYes(m.MortgageTPInsured))
                            shouldShowMortgageDetails = true;
                    }
                }
                
                const currentVal = m.MortgageAdvanceDate;
                state.orderChanges[`${mortgageIndexer}MortgageAdvanceDate`] = currentVal;
                state.shadowChanges[`${mortgageIndexer}MortgageAdvanceDate`] = currentVal;

                if (StringUtil.isEqual(fieldName, 'ClosingDate')) {
                    if (StringUtil.isNullEmptyOrWhiteSpace(currentVal)) {
                        state.orderChanges[`${mortgageIndexer}MortgageAdvanceDate`] = fieldValue;
                        state.shadowChanges[`${mortgageIndexer}MortgageAdvanceDate`] = fieldValue;
                    }
                }

                if (StringUtil.isEither(p.TransactionType, TransactionTypeEnum.MortgageOnly)) {
                    if (!StringUtil.isNullEmptyOrWhiteSpace(currentVal)) {
                        if (!StringUtil.isEqualDate(currentVal, state.closingDate)) {
                            isMortgageAdvanceDateDifferent = true;
                        }
                    }
                }

                if (StringUtil.isEqual(p.TransactionType, TransactionTypeEnum.MortgageOnly))
                    shouldShowMortgageDetails = true;
            }            
            state.orderChanges[`${mortgageIndexer}ShouldShowMortgageDetails`] = shouldShowMortgageDetails;
            state.orderChanges[`${mortgageIndexer}IsMortgageAdvanceDateDifferent`] = isMortgageAdvanceDateDifferent;
        }

        let priorityOfClosingMortgageChangeCount = state.orderChanges[`${propertyIndexer}PriorityOfClosingMortgageChangeCount`];

        if (StringUtil.isNullOrEmpty(priorityOfClosingMortgageChangeCount))
            priorityOfClosingMortgageChangeCount = 0;

        if (StringUtil.endsWith(fieldName, 'PriorityUponClosing'))
            priorityOfClosingMortgageChangeCount += 1;

        state.orderChanges[`${propertyIndexer}PriorityOfClosingMortgageChangeCount`] = priorityOfClosingMortgageChangeCount;
        state.orderChanges[`${propertyIndexer}ShouldShowMortgageSection`] = shouldShowMortgageSection;
        state.orderChanges[`${propertyIndexer}HasTpInsuredMortgage`] = hasTpInsuredMortgage;
        state.orderChanges[`${propertyIndexer}HasMortgage`] = hasMortgage;
        state.orderChanges[`${propertyIndexer}TotalPvtMortgageAmount`] = totalPvtMortgageAmount;
        state.orderChanges[`${propertyIndexer}TotalMortgageAmount`] = totalMortgageAmount;
        state.orderChanges[`${propertyIndexer}HasPvtLenderMortgage`] = hasPvtLenderMortgage;
        state.orderChanges[`${propertyIndexer}HasMortgageClosingPriorityOfLessThan1`] = hasMortgageClosingPriorityOfLessThan1;
        state.orderChanges[`${propertyIndexer}HasMortgageClosingPriorityOfLessThan3`] = hasMortgageClosingPriorityOfLessThan3;
        state.orderChanges[`${propertyIndexer}HideFraudQuestion`] = hideFraudQuestion;
        //#endregion Mortgages

        //#region Purchasers

        for (const purchaser of state.purchasers.map(x => purchasersHelper.getItemTrueState(x))) {
            const purchaserIndexer = QuestionaryHelper.createIndexer(CollectionTypeEnum.Purchasers, purchaser);

            const shouldDisableCorporationName = !StringUtil.isNullEmptyOrWhiteSpace(purchaser.PurchaserNameFirst) ||
                !StringUtil.isNullEmptyOrWhiteSpace(purchaser.PurchaserNameMiddle) ||
                !StringUtil.isNullEmptyOrWhiteSpace(purchaser.PurchaserNameLast);
            const shouldDisableIndividualName = !StringUtil.isNullEmptyOrWhiteSpace(purchaser.PurchaserNameCorporation);

            state.orderChanges[`${purchaserIndexer}ShouldDisableCorporationName`] = shouldDisableCorporationName;
            state.orderChanges[`${purchaserIndexer}ShouldDisableIndividualName`] = shouldDisableIndividualName;
        }

        const hasCorporatePurchaser = state.purchasers.map(x => purchasersHelper.getItemTrueState(x))
            .some(x => StringUtil.isEqual(p.Id, x.PurchaserPropertyId) && !StringUtil.isNullEmptyOrWhiteSpace(x.PurchaserNameCorporation));

        state.orderChanges[`${propertyIndexer}HasCorporatePurchaser`] = hasCorporatePurchaser;
        //#endregion Purchasers
    }

    state.orderChanges[`HasPurchaseTransaction`] = hasPurchaseTransaction;
    state.orderChanges[`HasExistingOwnerTransaction`] = hasExistingOwnerTransaction;
    state.orderChanges[`IsMOEOTransaction`] = isMOEOTransaction;

    //#region PremiumCalculator related items

    let calculatePremiumTriggerCount = 0;

    if (state.premiumChanges > 0)
        calculatePremiumTriggerCount = state.premiumChanges;

    if (StringUtil.endsWithEither(fieldName,
        //PropertyInfo
        '.PropertyType',
        '.TransactionType',
        '.CoverageType',
        '.PurchasePrice',
        '.PurchasePriceInclHST',
        '.PropertyAddressProvince',
        '.AddExistingOwnerPolicy',
        '.PropertyValue',
        //MortgageInfo
        '.MortgageType',
        '.MortgageLenderName',
        '.MortgagePrincipalAmount',
        '.RequestTPExtendedSuperPriorityLienEndorsement',
        '.MarketValueEndorsement'))
        calculatePremiumTriggerCount += 1;

    state.premiumChanges = calculatePremiumTriggerCount;
}

export default PolicyReducer;
